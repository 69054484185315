import { graphql } from 'gatsby';
import React from 'react';
import CategoryFilter from '../../components/blog/category-filter/category-filter';
import LandingHeader from '../../components/global/landing-header/landing-header';
import Layout from '../../components/global/layout/layout';
import RecentPosts from '../../components/global/recent-posts/recent-posts';
import { convertTagForUrl } from '../../utils/text';

const getBackgroundImageForTag = (data, tag) => {
    const svg = data.files.edges.find(edge => edge.node.name === convertTagForUrl(tag));
    if (svg) {
        return `/${svg.node.relativePath}`;
    }
    return '/images/blog-header.svg';
};

export default ({ pageContext, data }) => {

    return (
        <Layout footerPosts={data.posts}>
            <LandingHeader
                text={`Alles rund ums Thema ${pageContext.tag}`}
                backgroundImage={getBackgroundImageForTag(data, pageContext.tag)}
            ></LandingHeader>
            <CategoryFilter tags={data.tags.distinct}></CategoryFilter>
            <RecentPosts posts={data.posts}></RecentPosts>
        </Layout>
    );
}

export const pageQuery = graphql`
  query PostByTag($tag: String!) {
    posts: allPrismicBlogPost(
        limit: 3,
        filter: {
            tags: {
                eq: $tag
            }
        },
        sort: {
            fields: first_publication_date,
            order: DESC
        }
    )
    {
        nodes {
            uid
            data {
                title {
                    text
                }
                excerpt {
                    text
                }
                title_image {
                    fluid {
                      ...GatsbyPrismicImageFluid
                    }
                }
            }
            first_publication_date_from_now: first_publication_date(fromNow: true)
            first_publication_date_time: first_publication_date(formatString: "hh:mm")
            first_publication_date: first_publication_date(formatString: "DD.MM.YYYY")
            first_publication_date_day: first_publication_date(formatString: "dddd", locale: "de-DE")
            tags
        }
    }
    tags: allPrismicBlogPost {
      distinct(field: tags)
    }
    files: allFile(filter: {extension: {eq: "svg"}}) {
        edges {
            node {
                name
                relativePath
            }
        }
    }
  }
`
